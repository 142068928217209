/*
SIBEL INC ("SIBEL HEALTH") CONFIDENTIAL
Copyright 2018-2021 [Sibel Inc.], All Rights Reserved.NOTICE: All information contained herein is, and remains the property of SIBEL
INC. The intellectual and technical concepts contained herein are proprietary 
to SIBEL INC and may be covered by U.S. and Foreign Patents, patents in 
process, and are protected by trade secret or copyright law. Dissemination of
this information or reproduction of this material is strictly forbidden unless
prior written permission is obtained from SIBEL INC. Access to the source code
contained herein is hereby forbidden to anyone except current SIBEL INC 
employees, managers or contractors who have executed Confidentiality and 
Non-disclosure agreements explicitly covering such access.The copyright notice above does not evidence any actual or intended 
publication or disclosure of this source code, which includes information that
is confidential and/or proprietary, and is a trade secret, of SIBEL INC.ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC
DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN
CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE
LAWS AND INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE
CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO
REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR
SELL ANYTHING THAT IT MAY DESCRIBE, IN WHOLE OR IN PART.
*/

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "node-fetch";
import "./ConfirmUser.css";
import PageHeader from "../components/PageHeader.js";
import PageFooter from "../components/PageFooter.js";

/*
  ConfirmUser.js:
  - Path: /confirmUser
  - Query Parameters: confirmation_code, user_name, client_id
  - Description: This is the page for confirming the users after they sign up
*/

const ConfirmUser = () => {
  const [displayData, setDisplayData] = useState({
    message: "",
    details: "",
  });

  const API_PATH = "/confirmUser"

  const location = useLocation();
  var search = location.search;
  const path = location.pathname;

  if (search == "") {
    var params = path.split("/")
    search = "?client_id=" + params[2] + "&user_name=" + params[3] + "&confirmation_code=" + params[4]
  }

  useEffect(() => {
    let endpoint = new URL(process.env.REACT_APP_API_BASE_URL + API_PATH);
    endpoint.search = search;
    fetch(endpoint)
      .then((response) => {
        if (response.status === 200) {
          setDisplayData({
            message: "Verification successful",
            details:
              "Your email has been confirmed. You may now login and access the ANNE Care app",
          });
        } else if (response.status === 401) {
          setDisplayData({
            message: "Verification failure due to registration email time out",
            details:
              "Your verification link has expired. Please sign up again through the ANNE Care app",
          });
        } else {
          setDisplayData({
            message: "Verification failure due to server error",
            details:
              "Your email could not be verified, please try again later. If the problem persists, please contact Sibel Health at support@sibelhealth.com",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setDisplayData({
          message: "Verification failure due to server error",
          details:
            "Your email could not be verified, please try again later. If the problem persists, please contact Sibel Health at support@sibelhealth.com",
        });
      });
  }, []);

  return (
    <div className="page-wrapper">
      <PageHeader />
      <div className="verification-message">{displayData.message}</div>
      <div className="verification-details">{displayData.details}</div>
      <PageFooter />
    </div>
  );
};

export default ConfirmUser;
